.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 86px;
  background-color: transparent;
  color: #333;
  transform: translateY(0);
  transition: transform 0.6s ease;
}

.header3 {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 86px;
  background-color: transparent;
  color: #333;
  transform: translateY(0);
  transition: transform 0s ease;
  animation: myanimation 3s;
}

.header2 {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 86px;
  background-color: #303030;
  color: rosybrown;
  transform: translateY(10);
  transition: transform 6s ease;
  animation: headeranimation 3s;
}

@keyframes headeranimation {
  0% {
    background-color: transparent;
    color: transparent;
  }
  35% {
    background-color: #303030;
    color: rosybrown;
  }
  100% {
    background-color: #303030;
    color: rosybrown;
  }
}

@keyframes myanimation {
  0% {
    background-color: #303030;
    color: transparent;
  }
  35% {
    background-color: #303030;
    color: rosybrown;
  }
  100% {
    background-color: transparent;
    color: rosybrown;
  }
}

.logo {
  margin: 0 24px;
  font-size: 28px;
  color: #f59596;
}

.links {
  padding: 0;
  margin: 0 24px;
}

.link-item {
  display: inline-block;
  margin: 0 12px;
  cursor: pointer;
}

.chatbot {
  float: right !important;
  cursor: pointer;
  text-align: right;
}

.cookies {
  --mdb-btn-right: 2.1875rem;
  --mdb-btn-bottom: 2.1875rem;
  --mdb-btn-zindex: 1030;
  --mdb-btn-padding-top: 0.9375rem;
  --mdb-btn-padding-bottom: 1.25rem;
  --mdb-btn-padding-x: 1.25rem;
  --mdb-btn-margin-bottom: 1.5rem;
  position: fixed;
  left: var(--mdb-btn-right);
  bottom: var(--mdb-btn-bottom);
  z-index: var(--mdb-btn-zindex);
  display: flex;
  /* flex-flow: column-reverse nowrap; */
  align-items: center;
  padding: var(--mdb-btn-padding-top) var(--mdb-btn-padding-x) var(--mdb-btn-padding-bottom);
  margin-bottom: 0;
  height: auto;
  overflow: hidden;
  text-align: center;
}

.BotStyle {
  border-radius: 10px;
}

.carousel {
  color:  rgb(100, 100, 100);
  font-weight: bold;
}
