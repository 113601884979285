body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-left: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container-fluid {
  padding: 0% !important;
}

.nav-link {
  color: #8babe0 !important;
  font-weight: bold;
}

.header-1 {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-transform: uppercase;
  color: #1E88E5 !important;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
}

.header-text-align {
  text-align: start;
}

.footer-align {
  text-align: center;
}

.font-color {
  color: rgb(168, 168, 168);
  font-style: normal;
}


.header-1-background {
  background-color: (rgb(10 32 42));
}

.header-2 {
  color: #1E88E5 !important;
  font-weight: bold;
}

.header-3 {
  color: #1E88E5 !important;
  font-weight: bold;
  font-size: medium;
  padding-top: 10px;
}

#home a.active,
#applications a.active,
#technology a.active,
#responsibility a.active,
#categories a.active {
  color: white;
  text-decoration: underline !important;
}

.accordion-item {
  color: white !important;
}

.header-main-1 {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: #ffffff !important;
  text-align: center;
  font-size: 40px;
  margin-bottom: 1.5rem;
}
.header-main {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-transform: uppercase;
  color: #ffffff !important;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  margin-bottom: 1.5rem;
}

.row {
  --mdb-gutter-x: 1.5rem;
  --mdb-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1* var(--mdb-gutter-y));
  margin-right: 0% !important;
  margin-left: calc(-0.5* var(--mdb-gutter-x));
}